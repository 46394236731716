import axios from 'axios'
import React, { Component } from 'react'
import { Field, Form } from 'react-final-form'
import { ReactSVG } from 'react-svg'
import { trFunction } from 'utils/functions'

class ContactForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      postedEmail: null,
      error: null,
    }
  }

  render() {
    const locale = this.props.data.site.siteMetadata.locale
    const translations = this.props.data.translations
    const tr = (code) => trFunction(code, translations, locale)

    // const sampleData = {
    //   inquiryType: "inquiryType",
    //   fullName: "fullName",
    //   email: "email",
    //   companyName: "companyName",
    //   country: "country",
    //   referralSource: "referralSource",
    //   subjectField: "subject",
    //   message: "message"
    // };

    const onSubmit = (values) => {
      this.setState({ error: null, postedEmail: null, loading: true })
      const postObj = { form: values, locale, translations }

      // const url = 'http://localhost/stellar-js/email/emails/contact/email.php';
      const url =
        'https://stellar.lanternbox.com/email/emails/contact/email.php'

      const that = this
      axios
        .post(url, postObj)
        .then(function (response) {
          setTimeout(
            () => that.setState({ postedEmail: true, loading: false }),
            500,
          )
        })
        .catch(function (error) {})
    }

    // TODO: radio buttons

    return (
      <Form
        onSubmit={onSubmit}
        initialValues={{ inquiryType: tr('FORM_ITEM_GENERAL_INQUIRY') }}
        render={({ handleSubmit, form, submitting, pristine, values }) => (
          <form
            id="myForm"
            className="woocomerce-form wpcf7-form"
            onSubmit={handleSubmit}
            // noValidate
          >
            <p className="form-row form-row-wide">
              <label className="radio-custom">
                <Field
                  name="inquiryType"
                  component="input"
                  type="radio"
                  value={tr('FORM_ITEM_GENERAL_INQUIRY')}
                />
                {tr('FORM_ITEM_GENERAL_INQUIRY')}
                <span className="checkmark" />
              </label>
              <label className="radio-custom">
                <Field
                  name="inquiryType"
                  component="input"
                  type="radio"
                  value={tr('FORM_ITEM_CONTRACT_INQUIRY')}
                />
                {tr('FORM_ITEM_CONTRACT_INQUIRY')}
                <span className="checkmark" />
              </label>
              <label className="radio-custom">
                <Field
                  name="inquiryType"
                  component="input"
                  type="radio"
                  value={tr('FORM_ITEM_DISTRIBUTOR_INQUIRY')}
                />
                {tr('FORM_ITEM_DISTRIBUTOR_INQUIRY')}
                <span className="checkmark" />
              </label>
            </p>

            <p className="form-row form-row-first">
              <Field
                name="fullName"
                component="input"
                type="text"
                placeholder={`${tr('FORM_ITEM_FULL_NAME')}*`}
                required
              />
            </p>
            <p className="form-row form-row-last">
              <Field
                name="email"
                component="input"
                type="email"
                placeholder={`${tr('FORM_ITEM_EMAIL')}*`}
                required
              />
            </p>
            <p className="form-row form-row-first">
              <Field
                name="companyName"
                component="input"
                type="text"
                placeholder={`${tr('FORM_ITEM_COMPANY')}*`}
                required
              />
            </p>
            <p className="form-row form-row-last">
              <Field
                name="country"
                component="input"
                type="text"
                placeholder={`${tr('FORM_ITEM_COUNTRY')}*`}
                required
              />
            </p>
            <p className="form-row form-row-wide">
              <Field
                name="referralSource"
                component="input"
                type="text"
                placeholder={tr('FORM_ITEM_REFERRAL_SOURCE')}
              />
            </p>
            <p className="form-row form-row-wide">
              <Field
                name="subjectField"
                component="input"
                type="text"
                placeholder={`${tr('FORM_ITEM_SUBJECT')}*`}
                required
              />
            </p>
            <p className="form-row form-row-wide">
              <Field
                name="message"
                component="textarea"
                placeholder={tr('FORM_ITEM_MESSAGE')}
              />
            </p>

            <div className="form-row form-row-wide form-buttons">
              <div
                className={`submit-wrap ${this.state.loading ? 'loading' : ''}`}
              >
                <p className="note">*{tr('FORM_ITEM_REQUIRED_FIELDS')}</p>
                <span className="loader">
                  <ReactSVG src="/images/loader.svg" />
                </span>
                <input
                  type="submit"
                  value={tr('FORM_ITEM_SEND')}
                  className="wpcf7-form-control wpcf7-submit"
                  disabled={this.state.loading}
                />
              </div>
            </div>
            <div className={`thankyou ${this.state.postedEmail ? 'show' : ''}`}>
              <p>{tr('FORM_PRODUCT_INQUIRY_SUCCESS')}</p>
            </div>
          </form>
        )}
      />
    )
  }
}

export default ContactForm
