import React from 'react'
import ContactForm from 'components/forms/contact'
import Layout from 'components/layout'
import Title from 'components/title'
import OfficeGrid from 'components/officeGrid'
import { trFunction } from 'utils/functions'
import { graphql } from 'gatsby'

const Contact = ({ data }) => {
  const locale = data.site.siteMetadata.locale
  const translations = data.translations
  const tr = (code) => trFunction(code, translations, locale)
  const page = data.thisPage
  const offices = data.allOffices.edges
  const afterTitle = `${tr('OFFICE')} / ${tr('SHOWROOM')}`

  return (
    <Layout>
      <div id="content" className="page-template-page-contact">
        <Title title={page.title} afterTitle={afterTitle} />
        <OfficeGrid offices={offices} links={true} data={data} />
        <section className="page-content wrap">
          <ContactForm data={data} />
        </section>
      </div>
    </Layout>
  )
}
export default Contact

export const contactQuery = graphql`
  query {
    site {
      siteMetadata {
        locale
      }
    }
    translations: allContentfulStringTranslations {
      edges {
        node {
          code
          en
          zh
        }
      }
    }
    thisPage: contentfulPages(slug: { eq: "contact" }) {
      title
      slug
    }
    allOffices: allContentfulOffices(sort: { fields: [order] }) {
      edges {
        node {
          id
          title
          address1
          address2
          phone
          email
        }
      }
    }
  }
`
